.description-wrapper {
  flex: 1 1 auto;

  .description-header {
    height: 32px;
    background: #1d283a;
    border-radius: 4px;
    margin: 15px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    color: #76859c;
    padding: 0 5px;
    display: flex;
    width: min-content;
    align-items: center;
  }

  .description-content {
    font-size: 16px;
    line-height: 120%;
    color: #76859c;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 24px 0;
    text-align: start;

    & .default__description-content {
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}

.instructions-wrapper {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .instructions-download-btn {
    color: white;
    height: 48px;
    border: 1px solid #18a0fb;
    box-sizing: border-box;
    border-radius: 12px;
    background: transparent;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
}
