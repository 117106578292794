body,
body .p-component {
  font-family: Gotham Pro, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-decoration: none;
  color: $text-color;

  a {
    text-decoration: none;
  }
}

@media screen and (max-width: 1400px) {
  html {
    font-size: 15px;
  }
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 998px) {
  html {
    font-size: 13px;
  }
}

@media screen and (max-width: 576px) {
  html {
    font-size: 11px;
  }
}
