.devices-filter-block {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 0.5rem;

  .device-filter-dropdown {
  }

  h2 {
    min-width: 120px;
  }
}
.common-devices-filter {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  flex: 1 1 auto;
}
