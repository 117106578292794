body #root .p-inputtext.common-input,
body .p-dropdown {
  height: 3rem;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
  border-style: solid;
  border-width: 1px;
  background: $input-bg-color;
  border-color: $base-border-color;
  color: $label-color !important;
  display: flex !important;
  align-items: center !important;

  &::placeholder {
    color: $text-color;
  }

  &:hover,
  &:focus {
    border-color: $primary-color;
    background: $input-active-bg-color;
    color: #ffffff;
  }

  &.p-error {
    background-color: $input-error-bg-color !important;
    border-color: $error-color;
  }
}

.p-inline-message.p-inline-message-error {
  background-color: transparent !important;
  border: 0 none;
  padding: 6px 0;
  .p-inline-message-text {
    color: $error-color !important;
    font-size: 0.8rem;
    justify-content: flex-start;
  }

  .p-inline-message-icon {
    display: none;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #0c1729 !important;
  -webkit-text-fill-color: white !important;
  color: white !important;
  &:hover,
  &:focus {
    border-color: $primary-color;
    background: $input-active-bg-color !important;
    color: #ffffff;
  }
}

@import 'modifyForms';
