@import 'uiKit/index';
@import 'fonts/fonts';
@import 'elments/buttons';
@import 'elments/caledar';
@import 'elments/forms/radioBtn';

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background-image: url('../../../assets/images/bgImg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;

  #root {
    height: 100%;
    width: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
  }
}

.w-100 {
  width: 100% !important;
}
.w-content {
  width: min-content !important;
}

.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}

.wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.text-center {
  text-align: center;
}

.p-paginator {
  padding: 10px;
  background: transparent;
}

.p-flex {
  display: flex;
}

.relative {
  position: relative;
}
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}
