body #root .common-button.p-button.p-component {
  height: 3rem;
  box-sizing: border-box;
  border-radius: 10px !important;
  padding: 0.5rem 0.8rem !important;
  font-size: 0.85rem;
  border-style: solid;
  border-width: 1px;
  background: $input-bg-color;
  border-color: $action-btn-color;
  color: $label-color;
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
  white-space: nowrap;

  svg {
    height: 25px;
    width: 25px;
    margin-right: 10px;
  }

  &:hover {
    border-color: $action-btn-color;
    background: $primary-color !important;
  }
}
body #root .common-button.p-button.p-component.btn-padding {
  padding: 0 3rem !important;
}

body #root .secondary-btn.p-button.p-component {
  height: 3rem;
  box-sizing: border-box;
  border-radius: 10px !important;
  padding: 0.5rem 0.8rem !important;
  font-size: 0.85rem;
  border-style: solid;
  border-width: 1px;
  background: transparent;
  border-color: #1d283a;
  color: $label-color;
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
  white-space: nowrap;

  svg {
    height: 25px;
    width: 25px;
    margin-right: 10px;
  }

  &:hover {
    background: #1d283a;
    border-color: #1d283a !important;
  }
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  height: 3rem;
  box-sizing: border-box;
  border-radius: 10px !important;
  padding: 0.5rem 0.8rem !important;
  font-size: 0.85rem;
  border-style: solid;
  border-width: 1px;
  background: $input-bg-color;
  border-color: $action-btn-color;
  color: $label-color;
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    border-color: $action-btn-color;
    background: $primary-color !important;
  }
}
.danger--button {
  border: 1px solid $danger-elem !important;
  height: 32px;
  box-sizing: border-box;
  background: transparent !important;
  border-radius: 10px !important;
  padding: 0.5rem 0.8rem !important;
  font-size: 0.85rem;
  color: $label-color;
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
  white-space: nowrap;

  .pi,
  a,
  span {
    color: white !important;
    font-size: 0.85rem !important;
  }

  &:hover {
    background: $danger-elem !important;
    border: 1px solid $danger-elem !important;
  }
}
