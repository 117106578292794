@import '../../assets/styles/main-style/modules/colors';

.desktop-version-page {
  min-height: 400px;

  .desktop-download-title {
    text-align: left;
    color: $active-item;
  }
  .pi-download {
    font-size: 22px;
  }
  .download-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 30px;
    border: 1px solid $active-item;
    overflow: hidden;
    height: 3.5rem;
    max-height: 45px;
    width: 100%;

    $dropdown-percentage: 25%;

    &__btn {
      display: block;
      height: 100%;
      border-radius: 0 !important;
      box-shadow: none !important;
      border: none !important;
      font-size: 1.2rem;
      background: $active-item;
      width: 300px;

      button {
        height: 100%;
        color: #ffffff !important;
        background: unset !important;
        width: 100% !important;
        font-size: 1.3rem;
      }
    }

    &__dropdown {
      width: $dropdown-percentage;
      height: 100%;
      background: $active-item;
      border: none !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      color: #ffffff !important;

      &:hover {
        background: rgba($active-item, 0.92);
        color: #0b314b;
      }

      span {
        background: unset !important;
        font-size: 1.2rem;
      }
    }

    &__btn,
    &__dropdown {
      &:hover {
        background: #ffffff !important;

        span {
          color: $active-item !important;
        }
      }
    }
  }

  .platforms-toggler {
    color: $active-item;
    cursor: pointer;
    margin: 15px auto 0;
    font-size: 1.2rem;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }

  .download-group-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80pxpx;
    a {
      height: 3.5rem;
    }
  }
}
