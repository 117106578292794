.community-item-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 10px;

  &.pending {
    background: #ffab00;
  }
  &.rejected {
    background: #d4000f;
  }
  &.published {
    background: #18a0fb;
  }
}
