.share-profile-modal {
  .common-modal-header {
    height: 80px;
    margin-bottom: 10px;
    border-bottom: 1px solid #1d283a;
  }
  .common-modal-content {
    background: #111c2e;
    border-radius: 12px;
  }
  .header-icon {
    margin: 0 !important;
    cursor: pointer;

    $size: 20px;

    svg {
      width: $size;
      height: $size;
    }
  }

  .page-content-wrapper {
    border: none;
    box-shadow: none;
  }
}
