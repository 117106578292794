.auth-head-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;

  .logo {
    margin-bottom: 2rem;
    width: 3rem;
  }

  &-label {
    font-size: 24px;
    line-height: 120%;
    color: #ffffff;
  }
}

.auth-head-line-title {
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  margin: 1rem 0;
  width: 100%;
  display: inline-block;
}

.alternative-sign-title {
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  margin: 1rem 0;
  white-space: nowrap;
  display: inline-block;
}
