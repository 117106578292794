.common-bar-wrapper {
  margin: 0 0.5rem;
  padding: 0 0.5rem;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex: 1 1 auto;

  .quantity-call-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .quantity-call-info__elem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1rem;
    margin: 0.5rem 0;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
    width: 100%;

    &:last-child {
      border-right: none;
    }
  }

  .quantity-statistics-info__elem-value {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    .primary-meaning {
      font-size: 18px;
    }
  }

  .primary-meaning {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    padding-right: 12px;
    white-space: nowrap;
  }

  .action-type-label {
    color: #18a0fb;
  }

  .quantity-call-info__elem-value {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    /*__________colors for label__________*/
    .fa-circle {
      font-size: 14px;
      vertical-align: middle;
      margin-right: 5px;
      line-height: 27px;
    }

    .all-call {
      color: #17a2b8;
    }

    .in-call {
      color: #92e66a;
    }

    .first-call {
      color: rgb(95, 150, 72);
    }

    .out-call {
      color: #213991;
    }

    .miss-call {
      color: #eb5757;
    }

    .wait-call {
      color: #f2c94c;
    }

    /*__________________________________-*/

    .percentage-change {
      margin-right: 6px;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      white-space: nowrap;
    }

    .percentage-change.up {
      color: #6dd453;
    }

    .percentage-change.down {
      color: #fd8181;
    }
  }

  .trend-change__value-up {
    width: 30px;
    height: 30px;
    background: #e2f6dc;
    border-radius: 6px;
    display: flex;
    border: 1px solid #6dd453;
    align-items: center;
    justify-content: center;
    margin-left: 14px;

    svg {
      height: 20px;
      width: 16px;
    }
  }

  .trend-change__value-down {
    border: 1px solid #fd8181;
    width: 30px;
    height: 30px;
    background: #ffe6e6;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 14px;

    svg {
      height: 20px;
      width: 16px;
    }
  }

  .quantity-call-info__label {
    font-size: 15px;
    margin: 0;
    white-space: nowrap;
  }

  p.quantity-call-info__label {
    margin: 0;
  }
}
