body .p-inputtext {
  &.full {
    width: 100%;
  }

  .p-dropdown-label {
    max-height: 30px;
  }
}

.verify-code-input {
  width: max-content !important;

  input {
    width: 45px !important;
    height: 55px !important;
  }
}

.two-factor-checkbox {
  display: flex;
  align-items: center;
}

.p-message {
  margin: 4px 0 !important;
}

label {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.7px;
  margin-bottom: 8px;
  color: $label-color;
  display: inline-flex;
  align-items: center;
}

.p-d-none {
  display: none !important;
}

.p-dropdown-clearable .p-placeholder {
  text-transform: capitalize;
}

.p-inputtext::placeholder,
.p-dropdown-label.p-placeholder {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem !important;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  color: #acb5bd !important;
}

body .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  border-color: #18a0fb !important;
}

body .p-checkbox {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 40px;
  height: 37px;
  z-index: -14;

  .p-checkbox-box {
    border: 1px solid #18a0fb;
    box-sizing: border-box;
    border-radius: 10px;
    width: 35px;
    height: 35px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;

    &.p-highlight {
      border: 1px solid #18a0fb !important;
      background: #18a0fb !important;
    }
  }

  &:focus-visible {
    outline: none !important;
  }
}

.p-checkbox-disabled {
  cursor: no-drop;
  opacity: 0.5;
}

.p-checkbox:not(.p-checkbox-disabled).p-checkbox-focused {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.p-checkbox:not(.p-checkbox-disabled):hover {
  box-shadow: 0 0 0 0 transparent !important;
}

body .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border: 1px solid #18a0fb;
  background: #18a0fb;
  color: #121212;
}

body
  .sign-card-checkbox-block
  .p-checkbox
  .p-checkbox-box:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0 transparent;
  border: 1px solid #18a0fb;
}

.checkbox-wrapper {
  height: 3.5rem;
}

body .p-checkbox-label {
  margin: 0;
  cursor: pointer;
}

body .p-checkbox .p-checkbox-box .p-checkbox-icon {
  overflow: hidden;
  position: relative;
  font-size: 22px;
  line-height: 32px;
  width: 35px;
  height: 35px;
}

body .sign-card-checkbox-block .checkbox-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .view-terms {
    color: #18a0fb;
    background: transparent;
    border: none;
    width: min-content;
    margin-left: 30px;
    cursor: pointer;
    font-size: 14px;
    line-height: 120%;
    font-family: Gotham Pro, sans-serif;
    font-weight: 400;
    padding: 6px 0;
    white-space: nowrap;
    margin-bottom: 7px;
    &:hover {
      text-decoration: underline;
    }
  }

  &.last-agree {
    border-bottom: 1px solid #1d283a;
    margin-bottom: 30px;
  }

  .p-checkbox {
    width: 24px;
    height: 24px;

    .p-checkbox-box {
      width: 24px;
      height: 24px;
      border-radius: 4px;

      .p-checkbox-icon {
        width: 24px;
        height: 24px;

        &.pi-check:before {
          left: 4px;
          top: -7px;
          font-size: 16px;
        }
      }
    }
  }
}

.p-inputtextarea.p-inputtext {
  min-height: 5.2rem;
  overflow: auto !important;
}

.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
  content: '\e909' !important;
  position: relative;
  border-right: none;
  border-bottom: none;
  left: 7px;
  top: 0;
  color: white;
  font-size: 21px;
}

body .select-device .p-checkbox-box .p-checkbox-icon:before {
  content: '\274C';
  top: 1px;
  left: 8px;
  position: relative;
  font-size: 14px;
}

.sign-card-checkbox-block {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  max-height: 380px;

  label {
    font-weight: 500;
  }
}
