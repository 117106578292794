.common-notification-modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 0;
  background: rgba(48, 64, 89, 0.4);
  backdrop-filter: blur(40px);
  z-index: 300;
  transition: 0.3s;
  display: flex;
  flex-direction: column;

  &.active {
    width: 340px;
    transition: 0.3s;
  }
  .common-notification-title {
    font-size: 18px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
  }
  .clear-notification-modal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    height: 32px;
    background: rgb(67, 73, 87);
    border-radius: 32px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 110%;
    color: #76859c;
    svg {
      margin-right: 7px;
    }
    &:hover {
      color: white;
    }
  }
  .close-notification-modal {
  }

  .common-modal-body {
    overflow: auto;
  }
}
