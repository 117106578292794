body .p-button {
  color: black;

  &:active:after {
    opacity: 0;
  }

  &:enabled:hover {
    color: #0b314b;
  }

  &.button-auto-width {
    width: auto;
    margin-right: 10px;
  }
}

.p-fileupload-choose input[type='file'] {
  z-index: 999;
}

.p-button.p-fileupload-choose.p-component.p-button-text-icon-left {
  width: auto;
  margin-right: 10px;
}

.download-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  position: static;
  height: 32px;
  border: 1px solid #18a0fb;
  box-sizing: border-box;
  border-radius: 6px;
  background: transparent;

  &:hover {
    background: #18a0fb;
    a {
      color: white;
    }
  }
}
