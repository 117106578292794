.notification-item-wrapper {
  background: #0a111d;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 1.2rem;

  .info {
    color: #9fa8da;
    text-align: center;
  }

  .event-description {
    margin: 1rem 0;
    font-size: 1rem;
    line-height: 120%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .notification-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .notification-time {
      font-size: 12px;
      line-height: 11px;
      color: #76859c;
    }

    .notification-item-headline {
      background: #1d283a;
      font-size: 12px;
      border-radius: 4px;
      padding: 0 1rem;
      height: 24px;
      width: min-content;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
    }
  }

  .notification-item-body {
    display: flex;
    flex-direction: column;
    color: white;
  }

  .notification-item-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 1.9rem;
    }
  }
}

.notification-view-link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  width: min-content;
  margin-left: auto;
  transition: 0.2s;
  text-transform: lowercase;

  i {
    scroll-margin-right: 10px;
  }

  &:hover {
    color: #2a80b9;
  }
}

.clear-notification-modal {
  .pi-arrow-right {
    font-size: 12px;
    margin-left: 7px;
  }
}
