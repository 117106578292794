.settings-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;

  .common-settings-menu {
    width: min-content;
    min-width: 170px;
    height: 0;
    position: absolute;
    z-index: -1;
    right: -20px;
    top: 55px;
    padding: 0.6rem 0;
    background: #1d283a;
    border-radius: 10px;
    opacity: 0;
    transition: 0.2s;
    font-size: 14px;
    color: #76859c;

    &:after {
      width: 15px;
      height: 15px;
      content: '';
      position: absolute;
      background: #1d283a;
      transform: rotate(45deg);
      right: 28px;
      top: -8px;
      opacity: 0;
    }
  }

  .settings-item {
    display: flex;
    flex-wrap: nowrap;
    height: 3rem;
    padding: 0 5px 0 15px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    background: transparent;
    border-top: 1px solid #0a1527;

    .p-dropdown-label {
      color: #76859c;
    }

    svg {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      color: #76859c;

      path {
        stroke: #76859c;
      }
    }

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #76859c;
    }

    .settings-item-label {
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
    }

    &:hover {
      background: #0a1527;
      color: white;
      .p-dropdown-label {
        color: white;
      }
      svg {
        color: white;

        path {
          stroke: white;
        }
      }

      a {
        color: white;
      }

      .settings-item-label {
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
      }
    }
  }

  .settings-btn {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    outline: none;
    transition: 0.2s;

    svg {
      color: #76859c;
      width: 1.2rem;
      height: 1.2rem;
      cursor: pointer;
      outline: none;
    }

    &:hover {
      svg {
        color: white;
      }
    }
  }
}

body .settings-item .p-dropdown {
  height: 90%;
  border: none;
  background: transparent;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 100%;

  &:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0 #000000;
    border-color: transparent;
  }

  .p-dropdown-trigger-icon.pi-chevron-down {
    display: none;
  }
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0 #000000;
  border-color: #18a0fb;
}
.active.settings-wrapper {
  .common-settings-menu {
    height: min-content;
    box-shadow: 8px 12px 24px rgba(0, 0, 0, 0.16);
    opacity: 1;
    transition: 0.2s;
    z-index: 100;

    &:after {
      opacity: 1;
    }
  }

  .settings-btn {
    svg {
      transform: rotate(180deg);
      transition: 0.2s;
    }
  }
}

body .settings-item .p-dropdown:not(.p-disabled):hover {
  border-color: transparent;
}
