.page-statistic {
  display: flex;
  flex-direction: column;
  height: 100%;

  .page-statistic-wrapper {
    display: flex;
    height: inherit;
    padding-bottom: 70px;

    .highcharts-background,
    .highcharts-button-box {
      fill: transparent;
    }

    .highcharts-contextbutton {
      path {
        fill: #acb5bd !important;
        stroke: #acb5bd !important;
      }
    }

    .highcharts-contextmenu {
      .highcharts-menu {
        box-shadow: 8px 12px 24px rgba(0, 0, 0, 0.16) !important;
        padding: 0.6rem 0 !important;
        background: #1d283a !important;
        border-radius: 10px !important;
        color: #76859c;

        .highcharts-menu-item {
          color: #76859c !important;
        }
      }
    }

    .highcharts-credits {
      display: none;
    }
  }
}

.tabs-statistic-wrapper {
  display: flex;
  margin: 1rem 0;
  padding: 0 0.8rem;
}

.highcharts-legend-item {
  text {
    color: #ffffff !important;
    fill: #ffffff !important;
    font-size: 12px !important;
  }
}

.highcharts-legend-item-hidden {
  text {
    color: #76859c !important;
    fill: #76859c !important;
    font-size: 12px !important;
  }
}

.device-cart-style {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.01);

  rect {
    fill: rgba(255, 255, 255, 0.01);
  }
}

.common-cart-style {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.01);
}

.chart-wrap {
  display: flex;
  justify-content: space-between;
  color: #ffffff;

  .bar-chart {
    height: 100%;
    width: 100%;
  }
}

.highcharts-data-label {
  text {
    color: #ffffff !important;
    fill: #ffffff !important;
    font-size: 12px !important;
  }
}

.highcharts-text-outline {
  display: none;
}
