body .sign-card {
  max-width: 400px;
  margin: 0 auto;
  width: inherit;
  flex: 1 1 auto;

  .form-text {
    font-family: Open Sans, sans-serif;
    color: $label-color;
    margin: 0;
  }

  .p-card-body {
    padding: 0 !important;
    width: 100%;
    height: 100%;
  }

  .sign-link {
    height: min-content;
    span {
      margin-left: 20px;
      color: $primary-color;
    }
  }

  .registry-msg-wrapper {
    display: flex;
    flex-wrap: nowrap;
    margin: 8px 0;
    color: #d4000f;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;

    .registry-msg-icon {
      margin-right: 10px;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
.alternative-sign-link {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.password-link-msg {
  margin: 0 auto;
  color: white !important;
}

.change-password {
  .sign-card-form-block {
    padding-top: 0;
  }
}
