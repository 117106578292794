.verified-info {
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0 15px;
  color: #ffecb3;
  display: flex;
  text-align: center;
}
.body-verified-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
