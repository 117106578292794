.common-status-template {
  padding: 5px 10px;
  color: white;

  &.pending {
    background: #2a80b9;
  }

  &.published {
    background: #16b89e;
  }

  &.rejected {
    background: #ea6053;
  }
}
