.share-item-wrapper {
  width: 100%;
  min-height: 130px;
  background: #0a1527;
  border-radius: 8px;
  margin: 12px 0;
  display: flex;
  flex-wrap: nowrap;
  padding: 16px;
  align-items: flex-end;

  .share-item-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .share-item-name {
      font-size: 16px;
      line-height: 120%;
      color: white;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 340px;
      margin: 12px 0;
    }

    .share-item-desc {
      margin-bottom: 0;
      opacity: 0;
      font-size: 0;
      transition: 0.3s;
    }

    .share-item-devices {
      display: flex;
      flex-wrap: nowrap;

      .item-device {
        padding: 0 5px;
        height: 24px;
        background: #1d283a;
        border-radius: 4px;
        font-size: 12px;
        line-height: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #76859c;
        margin-right: 4px;
      }
    }
  }

  &:hover {
    background: #020d1f;

    .share-item-desc {
      font-size: 13px;
      line-height: 120%;
      color: #76859c;
      max-height: 30px;
      overflow: hidden;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      transition: 0.3s;
      opacity: 1;
      margin-bottom: 12px;
    }

    .common-button {
      background: #18a0fb !important;
    }
  }
}
.community-item-active-date {
  display: flex;
  align-items: center;

  .active-date {
    color: white;
    font-size: 13px;
    margin-left: 10px;
  }

  svg {
    height: 19px;
    width: 19px;
  }
}

body #root .share-item-wrapper .common-button.p-button.p-component {
  height: 32px !important;
  border-radius: 6px !important;
  padding: 0 20px !important;
}
