.mail-custom-input {
  .pi-envelope {
    top: 1rem;
    position: absolute;
    right: 15px;
  }
  &:hover {
    color: white;
  }
}
.password-custom-input {
  .pi {
    top: 1rem;
    position: absolute;
    right: 15px;
  }
  cursor: pointer;
  &:hover {
    color: white;
  }
}
