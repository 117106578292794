.contact-pages-title-info {
  font-size: 24px;
  line-height: 120%;
  color: #ffffff;
  margin: 0 0 48px 0;
  width: 100%;
  text-align: center;
}

.contact-pages-wrapper {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  padding: 2% 0;
  overflow: auto;

  .contact-content {
    max-width: 445px;
    margin: 10vh auto 0;

    label {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      color: #76859c;
    }

    .contact-pages-submit-btn {
      width: 100% !important;
      margin-top: 30px;
    }
  }
}
