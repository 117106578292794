$error-color: #d4000f;
$primary-color: #18a0fb;

/*input*/

$label-color: #ffffff;
$text-color: #76859c;
$base-border-color: #1d283a;
$active-border-color: #0a1527;
$base-bg-color: #1d283a;
$active-bg-collor: #0a1527;
$input-bg-color: rgba(255, 255, 255, 0.01);
$input-error-bg-color: rgba(212, 0, 15, 0.1);
$input-active-bg-color: rgba(255, 255, 255, 0.1);

/*button*/

$btn-color: #385167;
$action-btn-color: #18a0fb;

$body-bg: #2d3e50;
$action-bg-color: #0d273b;
$main-bg-color: #e5e5e5;
$modal-bg-color: #354b60;
$active-item: #9fa8da;

$active-menu-item: #c0a785;
