.device-card-content-wrapper {
  position: fixed;
  padding-top: 3rem;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
  background-image: url('../../../assets/images/bgImg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 100;

  .device-card-content {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
    width: 100%;
  }
}

.go-back-button {
  z-index: 5000;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #1d283a;
  box-sizing: border-box;
  border-radius: 10px;
  width: 48px;
  min-height: 48px;
  cursor: pointer;
  margin-left: 1rem;
  margin-right: auto;

  svg {
    height: 30px;
    width: 30px;
    color: white;
  }

  &:hover {
    border: 1px solid #18a0fb;
    background: #1d283a;
  }
}

.devices-scroll-content {
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.no-profile-msg-block {
  height: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .no-profile-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px;
    width: 80px;
    height: 80px;
    background: #1d283a;
    border-radius: 20px;
    margin: 32px 0px;

    svg {
      width: 30px;
      height: 32px;

      path {
        stroke: white;
      }
    }
  }

  .no-profile-msg {
    text-align: center;
    font-size: 24px;
    line-height: 120%;
    color: #ffffff;
    margin: 32px 0;
  }
}

.devices-content {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  justify-items: center;
  flex: 1 0 auto;
}

.load-promotion-btn {
  margin: 0 auto 20px !important;
  font-size: 14px !important;
  min-width: 3rem;

  svg {
    height: 16px !important;
    width: 16px !important;
  }
}

.common-devices-item-wrapper {
  width: 297px;
  height: 305px;
  background: #111c2e;
  border-radius: 16px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  .common-devices-item-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .devices-avatar-item {
      margin-right: 0.5rem;
    }

    .user-auth-info {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 250px;
    }

    svg {
      margin-left: auto;
      cursor: pointer;
      width: 25px;
      height: 25px;

      &:hover {
        color: white;
      }
    }

    .img-preview.user-avatar-profile {
      width: 24px;
      height: 24px;
      border-radius: 6px;
    }

    .user-auth-info {
      font-size: 14px;
      color: white;
      width: min-content;
      text-align: left;
    }
  }

  .common-devices-item-body {
    margin: 16px 0;
    background: #0a1527;
    border-radius: 8px;
    max-height: 190px;
    min-height: 190px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

    .light-effect-icon {
      position: absolute;
      right: 0;
      top: 0;
      margin: 11px;
    }

    .alt-msg {
      width: 185px;
      display: inline-block;
      text-align: center;
    }

    .devise-community-block {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .community-devise-img-block {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 145px;
      position: relative;
      top: 5px;

      img {
        max-height: 90%;
        max-width: 190px;
      }
    }

    .model-name {
      font-size: 14px;
      line-height: 13px;
      color: #76859c;
      display: flex;
      white-space: nowrap;
      padding: 10px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .common-devices-item-content {
    display: flex;
    flex-direction: column;

    .devices-item-name {
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      color: white;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 340px;
    }

    .devices-item-desc {
      font-size: 13px;
      line-height: 120%;
      color: #76859c;
      margin: 8px 0;
      max-height: 30px;
      overflow: hidden;
    }
  }

  .common-devices-item-footer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 auto;

    button {
      height: 32px !important;
      margin-left: auto;
    }
    .devices-item-download {
      display: flex;
      align-items: center;

      svg {
        height: 19px;
        width: 19px;
        margin-right: 12px;
      }

      .quantity {
        font-size: 14px;
        color: white;
      }
    }

    .devices-item-like {
      display: flex;
      align-items: center;

      svg {
        margin: 0 6px 0 27px;
        cursor: pointer;
        height: 19px;
        width: 19px;
      }

      .quantity {
        font-size: 14px;
        color: white;
      }

      &:hover {
        svg {
          color: white;
        }
      }
    }

    .devices-item-active-date {
      display: flex;
      align-items: center;

      .active-date {
        color: white;
        font-size: 13px;
      }

      svg {
        margin: 0 12px 0 24px;
        height: 19px;
        width: 19px;
      }
    }

    .profile-download-link {
      margin-left: auto;

      .common-button {
        height: 32px !important;
        border-radius: 6px !important;
      }
    }
  }

  &:hover {
    background: #1d283a;

    .profile-download-link {
      .secondary-btn {
        border: 1px solid #18a0fb !important;
      }
    }
  }
}

body #root .p-button.p-component.secondary-btn.device-category {
  padding: 6px !important;
  height: 24px !important;
  background: #1d283a !important;
  border-radius: 4px !important;
  cursor: pointer;
  color: #76859c;
  margin-right: 10px;

  .pi-times {
    color: #d4000f;
  }

  .pi-check {
    color: #18a0fb;
  }

  &:hover,
  &.active {
    color: white;
    border-color: #18a0fb !important;
  }
}
