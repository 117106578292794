.profile-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-profiles-wrapper {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
  overflow: auto;

  .profile-info-input-wrapper {
    position: relative;

    .p-inputtext.common-input {
      padding-left: 2.2rem !important;
    }

    svg {
      top: 1.5rem;
      position: absolute;
      left: 1.2rem;
      color: #76859c;
    }

    &:hover {
      svg {
        color: white;
      }
    }
  }

  .profile-info-submit-wrapper {
    margin-top: 2.6rem;
    padding-top: 2.6rem;
    border-top: 2px solid #1d283a;
    display: flex;
    justify-content: space-between;
  }

  .delete-your-account {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.5rem;

    &:hover {
      color: white;
    }

    svg {
      color: #18a0fb;
      height: 1.9rem;
      width: 1.9rem;
    }
  }

  .logout-btn {
    position: absolute;
    top: 50px;
    right: 0;
    height: 2.5rem;
    padding: 0 2rem;
    background: #1d283a;
    border: 1px solid #1d283a;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      margin-right: 20px;
    }

    &:hover {
      color: white;

      svg {
        color: white;
      }
    }
  }

  .profile-card-wrapper {
    max-width: 700px;

    .p-card-title {
      font-size: 2.3rem;
      color: #ffffff;
      margin-bottom: 2.5rem;
    }
  }

  .setting-navigation-wrapper {
    width: 72px;
    height: 364px;
    background: #020d1f;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .setting-navigation-item {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        cursor: pointer;
        height: 20px;
        width: 20px;
      }

      &:hover,
      &.active {
        svg {
          color: #ffffff;
        }

        &:after {
          content: '';
          position: absolute;
          height: 48px;
          width: 2px;
          background: #18a0fb;
          border-radius: 80px;
          right: 0;
        }
      }
    }
  }
}

.user-name {
  font-size: 2.3rem;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  margin: 1.9rem 0 2.3rem 0;
}

.avatar-submit-btn {
  z-index: 1;
  max-width: 2rem;
  height: 2rem;
  cursor: pointer;
  position: relative;
  top: -1.1em;
  align-items: center;
  justify-content: center;
  display: flex;

  svg {
    width: 2rem;
    height: 2rem;
  }

  &:hover {
    color: white;
  }
}

body #root .common-button.p-button.p-component.profile-info-submit-btn {
  padding: 0 2rem !important;
}

.user-avatar-profile {
  height: 9rem;
  width: 9rem;
  overflow: hidden;
  border-radius: 16px;
  margin-right: 1.8rem;
}

.profile-card-wrapper {
  .oauth-button-group {
    position: relative;
    justify-content: flex-start;

    .oauth-btn {
      width: 6rem;
      display: flex;
      justify-content: center;
      margin-right: 6rem;
    }

    .profile-delete-oauth-btn {
      cursor: pointer;
      padding: 3px !important;
      height: min-content !important;
      position: relative;
      right: 1.3rem;
      top: -0.9rem;
      z-index: 100;

      &:hover {
        color: white;
      }
    }
  }
}
