.download-btn {
  margin-left: auto;
  a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    color: white;
  }

  &:hover {
    background: #18a0fb;

    a {
      color: white;
    }
  }
}
