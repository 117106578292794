.common-device-headline {
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 38px;
  color: #ffffff;

  .device-category {
  }

  .device-company,
  .device-model {
  }

  .device-model {
  }
}
