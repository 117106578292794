.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.img-preview {
  overflow: hidden;
}

.cropper-modal-window {
  display: flex;
  align-items: center;
  justify-content: center;

  .common-modal-content {
    max-width: 600px !important;
    height: 60vh !important;
  }
}

.cropper-modal-window {
  .common-modal-content {
    background: $modal-bg-color;
    border: 3px solid #475b6f;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 1px rgba(225, 225, 225, 0.3);
  }

  .common-modal-body {
    flex: 1 1 auto;
    margin: 20px 20px 50px;
    .button-save-preview {
      width: 100px !important;
    }

    .modal-body-description {
    }

    .common-cropper-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .common-modal-header {
    min-height: 70px;
    padding: 0px 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .p-card-title {
      margin: 0;

      span {
        height: min-content;
        margin: 0;
      }
    }
    .button-close-dialog {
      top: 25px;
      color: white;
      svg {
        color: white;
      }
    }
  }
}

.reactEasyCrop_CropAreaRound {
}
