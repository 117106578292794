.logo-icon {
  height: 4.5rem;
  margin-right: 20px;
}

.user-avatar-info {
  height: 3rem;
  width: 3rem !important;
  border-radius: 12px;
}

.user-auth-info {
  color: white;
  font-size: 12px;
  line-height: 14px;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.main-page-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 1rem 2.5rem;
  box-sizing: border-box;
  position: relative;
  background-image: url('../../../images/bgImg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  flex: 1 1 auto;

  .main-page-headline {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.3rem;
    color: #19191d;
  }
}

.common-content-wrapper {
  overflow: auto;
}

.common-filter-wrapper {
  max-height: 4rem;
  transition: 0.3s;
  overflow: hidden;
  background: #365162;
  box-shadow: 0px 2px 5px -1px rgba(225, 225, 225, 0.3);
  border-radius: 0 0 6px 6px;

  .mobile-close-filter-button {
    @extend .common-btn;
    background: $active-item;

    span {
      display: inline-block;
      z-index: 999;
      right: 3px;
      top: 11px;
      transform: rotate(0deg);
      transition: 0.3s;
      cursor: pointer;

      svg {
        width: 30px;
        height: 30px;
        outline: none !important;
      }
    }
  }

  &.active {
    max-height: max-content;
    overflow: revert;

    .mobile-close-filter-button {
      z-index: 999;

      span {
        transform: rotate(180deg);
        transition: 0.3s;
      }
    }
  }
}

.common-block-headline {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.375rem;
  margin: 10px;
  color: #000000;
  display: inline-block;
}

.page-content-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: initial;
  z-index: initial;
  margin: 0 auto;
  width: 100%;
  flex: 1 1 auto;
  align-items: center;

  .common-pages-title-info {
    font-size: 1.2rem;
    font-weight: 600;
    padding-left: 2rem;
    color: white;
  }

  .content-wrapper-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    width: 100%;
    height: min-content;
  }
}

.p-breadcrumb ul li {
  &.p-breadcrumb-chevron {
    color: #acb5bd !important;
    font-size: 14px !important;
  }
}

.p-breadcrumb ul {
  position: relative;
  top: -9px;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  padding: 0 2rem;
  z-index: 10;
  height: 4rem;

  li:last-child .p-menuitem-link {
    color: $active-item !important;
  }
}

.common-device-item-wrapper {
  padding: 3rem;
  height: 25rem;

  .common-device-card {
    height: 100%;
    cursor: pointer;
    border-radius: 16px;

    .p-card-body {
      display: flex;
      flex-direction: column;
      height: 100%;

      .p-card-title {
        text-align: center;
        font-size: 1.3rem;
        padding: 1rem;
        letter-spacing: 5px;
      }

      .p-card-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;

        svg {
          width: 90px;
          height: 90px;

          path {
            stroke: #fff !important;
          }
        }
      }
    }
  }
}

body .p-breadcrumb ul li .p-menuitem-link:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.p-breadcrumb {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
  width: 100%;

  .p-menuitem-link {
    color: $action-btn-color !important;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }
}

.p-d-flex {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 1430px) {
  .p-xxl-1,
  .p-xxl-2,
  .p-xxl-3,
  .p-xxl-4,
  .p-xxl-5,
  .p-xxl-6,
  .p-xxl-7,
  .p-xxl-8,
  .p-xxl-9,
  .p-xxl-10,
  .p-xxl-11,
  .p-xxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .p-xxl-1 {
    width: 8.3333%;
  }

  .p-xxl-2 {
    width: 16.6667%;
  }

  .p-xxl-3 {
    width: 25%;
  }

  .p-xxl-4 {
    width: 33.3333%;
  }

  .p-xxl-5 {
    width: 41.6667%;
  }

  .p-xxl-6 {
    width: 50%;
  }

  .p-xxl-7 {
    width: 58.3333%;
  }

  .p-xxl-8 {
    width: 66.6667%;
  }

  .p-xxl-9 {
    width: 75%;
  }

  .p-xxl-10 {
    width: 83.3333%;
  }

  .p-xxl-11 {
    width: 91.6667%;
  }

  .p-xxl-12 {
    width: 100%;
  }

  .p-xxl-offset-12 {
    margin-left: 100%;
  }

  .p-xxl-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-xxl-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-xxl-offset-9 {
    margin-left: 75%;
  }

  .p-xxl-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-xxl-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-xxl-offset-6 {
    margin-left: 50%;
  }

  .p-xxl-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-xxl-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-xxl-offset-3 {
    margin-left: 25%;
  }

  .p-xxl-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-xxl-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-xxl-offset-0 {
    margin-left: 0%;
  }

  .p-xxl-order-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .p-xxl-order-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .p-xxl-order-0 {
    -ms-flex-order: 0;
    order: 0;
  }
}
