.application-nav-wrapper {
  background: #020d1f;
  border-radius: 10px;
  display: flex;
  height: 100%;
  max-width: 7%;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem;
  overflow: auto;

  .app-nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0.5rem;
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
    width: 100%;

    &:hover,
    &.active {
      background: #111c2e;

      .app-name {
        color: #fff;
      }

      &:after {
        content: '';
        position: absolute;
        height: 48px;
        width: 2px;
        background: #18a0fb;
        border-radius: 80px;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .app-name {
      font-size: 0.7rem;
    }

    .p-avatar-image {
      width: 100%;
      height: auto;
      margin: 0.5rem;

      img {
        width: auto;
        max-height: 30px;
      }
    }
  }
}
