.p-dropdown-panel {
  padding: 6px 0;
  background: #0a1527 !important;
}
body .p-dropdown {
  border-width: 2px;
  border-style: solid;
  border-color: $base-border-color;
  background: $input-bg-color;
  height: 2.5rem;
  border-radius: 3px;
  padding: 0 1rem !important;
  display: flex !important;
  align-items: center !important;
  box-shadow: 1px 1px 8px 3px rgba(11, 49, 75, 0.1);

  .p-dropdown-panel .p-dropdown-header {
    padding: 0;
    background: #111c2e;
    box-shadow: 8px 12px 30px rgba(0, 0, 0, 0.45);
    border-radius: 6px;
    border-bottom: none !important;
  }

  &.not-cleanable {
    .p-dropdown-clear-icon {
      display: none;
    }
  }

  .p-dropdown-items-wrapper {
    padding: 6px 0;
    background: #0a1527;
    border-radius: 6px;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  .p-dropdown-trigger {
    width: auto;

    .p-dropdown-trigger-icon {
      margin-left: 0.5em;
      color: #d2d2d2;
    }
  }

  & .p-dropdown-trigger,
  .p-dropdown-clear-icon {
    color: white;
    font-size: 12px;
    background: transparent;
  }

  & .p-dropdown-label {
    border: none !important;
    padding: 0 !important;
    height: auto !important;
  }

  &:hover {
  }

  &:not(.p-disabled):hover {
    border-color: $primary-color;
  }
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: inherit;
  &.p-highlight {
    color: white !important;
    background: #020d1f !important;
  }
  &:hover {
    color: white !important;
    background: #020d1f !important;
  }
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  background: transparent;
}
