@import '../../../assets/styles/main-style/modules/colors';

.common-card-wrapper {
  padding: 1.2rem 1rem;
  width: 100%;
}

.user-profile-card {
  position: relative;
  width: 100%;
}

body .p-card {
  background: $body-bg;
  color: white;
}
.p-card-content {
  b {
    color: $active-item;
  }
}

.p-card-title {
  color: white;
  font-size: 1.1rem;

  svg {
    margin-right: 13px;
    width: 25px;
    height: 25px;
    color: white;
  }
}

.common-profile-tab-wrapper {
  max-width: 950px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  background: $modal-bg-color;
  border: 3px solid #475b6f;
  border-radius: 6px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

body .p-tabview.p-tabview-top .p-tabview-nav .p-highlight.p-tabview-selected a {
  background: #304256 !important;
  box-shadow: 0px 2px 5px -1px rgba(225, 225, 225, 0.8) !important;
  border: none !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  font-size: 1rem;
  padding: 0 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 3rem;
  background: transparent;
  border-radius: 10px;
  margin-right: 1rem;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: white;
  background: #1d283a;
}
.common-desktop-version-block {
  width: 100%;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  display: none;
}
body .p-tabview .p-tabview-panels {
  background: transparent;
  border-radius: 0 0 6px 6px;
  overflow: auto;
  height: 100%;
  padding: 0;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-bottom .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-left .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-right .p-tabview-nav li a:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.p-tabview .p-tabview-nav {
  background: transparent;
  border: none;
  border-width: 0;
}
