.datatable-scroll-demo .loading-text {
  display: block;
  background-color: #f1f1f1;
  min-height: 19px;
  animation: pulse 1s infinite ease-in-out;
  text-indent: -99999px;
  overflow: hidden;
}

.p-paginator .p-paginator-current,
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0;
  color: hsla(0, 0%, 100%, 0.6);
  min-width: 2rem;
  height: 2rem;
  margin: 0.143rem;
}

body .p-datatable .p-datatable-scrollable-header,
body .p-datatable .p-datatable-scrollable-footer {
  background: $body-bg !important;
  border-radius: 6px 6px 0 0;
  border: 1px solid white;
}

.datatable {
  padding: 0;
  color: #fff;
  box-shadow: 0 1px 6px 2px rgba(225, 225, 225, 0.4);

  &.p-datatable {
    .p-datatable-header {
      border: 0 none;
      padding: 12px;
      text-align: left;
      font-size: 20px;
    }

    .p-paginator {
      padding: 0 3rem;
      background: $body-bg;
      height: 4rem;
      display: flex;
      align-items: center;
      border-radius: 0 0 6px 6px;

      .p-dropdown-panel {
        border-radius: 4px !important;

        .p-dropdown-items {
          .p-dropdown-item {
            &.p-highlight {
              color: #fff !important;
            }

            &:hover:not(.p-highlight) {
              color: #fff !important;
            }
          }
        }
      }
    }

    .p-datatable-thead > tr > th {
      min-height: 2.75rem;
      height: 2.75rem;
      font-size: 0.9rem;
      text-align: center;
      color: white;
      background: transparent;
      border: none;
      span.p-column-title {
        .pi {
          font-size: 1.3rem;
        }
      }
    }

    .p-datatable-tbody {
      & > tr > td {
        min-height: 2.75rem;
        height: 2.75rem;
        border: 0 none;
        font-size: 0.9rem;
        cursor: auto;
        background: $btn-color;
        color: white;
        border-bottom: 0.6px solid #dde2e5;
        text-align: center;
        padding: 0.8rem;

        td {
          min-height: 1rem !important;
          height: 1rem !important;
          color: $active-item !important;
          padding: 0.2rem;
          border-bottom: none !important;
          background: $input-bg-color;
        }

        a {
          color: #9fa8da;
        }

        .action-button-wrapper {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-around;
          align-items: center;

          button {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .p-paginator-pages {
    height: auto;
  }

  & .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border-radius: 4px;
  }

  .p-paginator-icon {
    color: #e6e7eb;
  }

  & .p-link:focus {
    box-shadow: none;
  }

  & .pi-trash {
    font-size: 14px;
    color: red;
    cursor: pointer;
  }

  & .p-paginator.p-paginator-bottom {
    & .p-paginator-current {
      font-family: Proxima Nova, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 30px;
      color: white;
      margin-left: auto;
    }
  }
}

.p-checkbox-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  border-color: transparent;
}

.config-sub-table {
  background: $input-bg-color;
  border-radius: 5px;
  padding: 5px;

  .heat-title-sub-table {
    font-size: 1rem;
    color: $primary-color;
    text-align: left;
    margin: 5px 0 5px 2rem;
  }

  .sup-table {
    tr {
      th {
        min-height: 2rem !important;
        height: 2rem !important;
        padding: 5px;
        font-size: 1rem !important;
      }

      td {
        background: $input-bg-color;
      }
    }
  }
}
.device-profile {
  .config-sub-table {
    .heat-title-sub-table {
    }

    .sup-table {
      tr {
        th {
          min-height: 0 !important;
          height: 0 !important;
          padding: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 40em) {
  body .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:after {
    width: 0 !important;
    height: 0 !important;
  }
}

body .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0 transparent;
  border-color: transparent;
}

.datatable.p-datatable {
  .p-datatable-tbody,
  .p-datatable-thead {
    .middle-td-style {
      width: 140px;
      white-space: normal;
      text-overflow: ellipsis;
    }

    .lg-td-style {
      width: 300px;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;

      &.description-device-profiles {
        font-size: 12px;
        text-align: left;
      }
    }

    .created-at-style,
    .date-style {
      width: 100px !important;
      white-space: normal;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .common-td-style {
      width: 200px;
      white-space: normal;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .little-td-style {
      width: 80px;
      white-space: normal;
    }
  }
}
