@import '~primeicons/primeicons.css';

body .p-calendar {
  cursor: pointer;
  align-items: center;

  & ~ label {
    margin-left: 10px;
  }

  .p-button-text-only {
    @extend .secondary-btn;
    display: inline;
  }
}

.p-fluid .p-calendar.p-calendar-w-btn .p-inputtext {
  width: 100% !important;
}

.p-calendar-w-btn {
  background: $input-bg-color;

  .pi-calendar:before {
    color: #d2d2d2;
  }

  .p-datepicker-trigger {
    .p-button-icon-only {
      height: 2.5rem;
    }

    .p-button-label {
      display: none;
    }
  }
}

.p-calendar .p-calendar-button {
  position: absolute !important;
  padding: 0 15px !important;
  top: 1px;
  right: 2px;
  height: 100% !important;
  display: flex !important;

  span {
    margin: 0 !important;
  }
}

body .p-datepicker {
  table {
    font-size: 13px;
  }

  table td > span {
    padding: 3px;
  }
}

.p-datepicker .p-datepicker-header {
  padding: 0 0.5rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2.5rem;
  height: 1.5rem;

  .pi {
    font-size: 0.8rem;
  }
}

.p-datepicker table td {
  padding: 0;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: black;
}
