.p-dropdown.p-component.p-inputwrapper-filled {
  background: transparent;
  box-shadow: 0 0 0 0;
  border-style: solid;
  border-width: 1px;
  border-color: #1d283a;

  .p-dropdown-label.p-inputtext {
  }

  & label.p-dropdown-label.p-inputtext {
    box-sizing: border-box;
    width: 60px;
    height: 33px !important;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-left: 10px;
    border-radius: 6px;
    letter-spacing: 0.44px;
    padding: 0 7px !important;
    color: white;
  }

  .p-dropdown-trigger {
    right: 16px;

    .p-dropdown-trigger-icon {
      font-size: 14px;
      color: white;
    }
  }
}
.p-calendar-w-btn {
  border: 1px solid #1d283a;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #1d283a;

  & .p-datepicker-header {
    background: #1d283a;
  }
}
.p-datepicker table td > span.p-highlight {
  color: #18a0fb;
}
.p-calendar-w-btn:not(.p-disabled):hover {
  border-color: #18a0fb;
}
