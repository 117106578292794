.common-profile-tab-wrapper {
  ul {
    .profile-tab-headline {
      display: flex;
      align-items: center;

      a {
        span {
          white-space: normal;
          text-align: center;
        }
      }
    }
  }
}

.common-tab-wrapper__table {
  overflow: hidden;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;

  .p-tabview-panels {
    padding: 0;
    flex: 1 1 auto;
    display: flex;

    .p-tabview-panel {
      display: flex;
    }
  }
}

.common-desktop-version-block {
  height: 460px;
  display: flex;
  flex-direction: column;

  .p-tabview-panels {
    flex: 1 1 auto;
  }
}

.p-tabview.p-tabview-panels {
  display: flex;
}

@media (max-width: 576px) {
  .common-profile-tab-wrapper {
    .p-tabview-nav {
      flex-direction: column;

      .profile-tab-headline {
        a {
          width: 100%;

          span {
            text-align: center;
            font-size: 16px;
          }
        }
      }
    }
  }
}
