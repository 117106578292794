.statistic-content-wrapper {
  min-width: 93%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 1rem;

  .p-dropdown {
    min-width: 150px;
    margin-right: 20px;
  }

  .statistic-content {
    height: 100%;
  }
}

.secondary-chart-wrapper {
  display: flex;
}
