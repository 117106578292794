.p-radiobutton .p-radiobutton-box {
  border: 2px solid #76859c;
  background: #1e1e1e;
  width: 20px;
  height: 20px;
  color: #18a0fb;
  border-radius: 50%;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  &:hover {
    border-color: #18a0fb;
  }
}

.p-radiobutton-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #18a0fb;
  background: #121212;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 10px;
  height: 10px;
  transition-duration: 0.2s;
  background-color: #18a0fb;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #18a0fb;
  background: #121212;
  color: #18a0fb;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border: 2px solid #76859c;
}
