body .p-inputswitch {
  width: 3rem;
  height: 1.5rem;
  border-radius: 80px !important;
  overflow: hidden;

  &.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #18a0fb !important;
    &:before {
      background: white;
    }
  }
  & .p-inputswitch-slider {
    background-color: rgb(26, 34, 47) !important;
    &:before {
      background: #76859c;
    }
  }
}
