::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #1d283a !important;
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  background: transparent !important;
  border-radius: 6px;
}
*:hover::-webkit-scrollbar-thumb {
  background: #1d283a !important;
  border-radius: 6px;
}
