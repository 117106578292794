@import '../../assets/styles/main-style/modules/colors';
@import '../../assets/styles/lorgar/uiKit/colors';

header {
  width: 100%;
  padding: 0 1rem;
  background: transparent;
  height: 6.5rem;
  min-height: 6.5rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-around;
  position: relative;

  .common-head-logo {
    height: 3rem;
    margin-right: 20px;
  }

  .burger-menu {
    z-index: -2;

    svg {
      width: 0;
      height: 0;
      color: transparent;
    }
  }

  .nav-container {
    padding: 0 2rem;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    .quota-toggler {
      cursor: pointer;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      box-shadow: none !important;
      background: inherit !important;
      border: none !important;
      padding: 0 1rem !important;

      span.pi {
        font-size: 20px;
        margin-left: 5px;
      }

      &:hover {
        background-color: #fff !important;

        span {
          color: #0b314b !important;
        }
      }
    }

    .quota {
      position: absolute;
      top: 6.5rem;
      left: 0;
      right: 0;
      background-color: #0b314b;
      border-top: 1px solid #fff;
      z-index: 4500;
      padding: 10px;

      &__item {
        position: relative;

        &.reload {
          background: white !important;

          span {
            color: #0b314b !important;
            font-weight: 600;
          }
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        .label {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 10px;
        }
      }
    }
  }

  .user-profile-block {
    display: flex;
    flex-wrap: nowrap;
  }
}

.nav-bar-menu {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  height: 45px;
  margin: 0;
  padding: 0;

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    list-style: none;
    margin: 0 1.5rem;
    position: relative;

    a {
      color: inherit !important;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      text-align: center;
    }

    &:hover,
    &.active {
      color: $label-color;

      &:after {
        position: absolute;
        width: 60%;
        content: '';
        background: $primary-color;
        border-radius: 70px;
        height: 2px;
        bottom: 0;
      }
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 5px;
    }
  }

  &-logo {
    display: flex;
    align-items: center;
    height: 3rem;
  }

  &-item__home-page {
    margin-right: 1rem;
    cursor: pointer;

    & svg {
      margin-bottom: 3px;
    }

    &:hover {
      label {
        color: white;
      }
    }

    label {
      padding: 0 0.4rem;
    }
  }

  &-item__logout {
    margin-left: 1rem;
    cursor: pointer;

    a {
      text-transform: lowercase;
      font-size: 0.9rem;
    }

    &:hover {
      label {
        color: white;
      }
    }
  }
}

@media (max-width: 568px) {
  body .p-progressbar .p-progressbar-label {
    font-size: 12px;
    padding: 0 20px;
  }
  header {
    .burger-menu {
      font-size: 16px;
      padding-top: 6px;
      left: 15px;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .nav-bar-menu-logo {
      position: absolute;
      right: 20px;
      font-size: 14px;
      z-index: 4000;

      span {
        font-size: 12px;
      }
    }

    .nav-container {
      .quota-toggler {
        left: 47%;
        font-size: 14px;
      }

      .pi {
        font-size: 15px !important;
      }
    }

    .main-page-wrapper .main-page-headline {
      margin: 5px auto;
      z-index: 3999;
      color: white;
      font-size: 18px;
      position: fixed;
      top: 14px;
      left: 70px;
    }

    .nav-bar-menu {
      flex-direction: column;
      z-index: 3000;
      width: 100%;
      align-items: self-start;
      font-size: 20px;
      left: 0;
      background: #052b3b;
      position: absolute;
      padding-top: 55px;
      height: 100vh;
      flex: 1 1 auto;
      top: 0;
      justify-content: end;

      &-item,
      .nav-bar-menu label {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 120%;

        a {
          justify-content: flex-start;
          border-bottom: 1px solid white;
        }
      }

      &.active {
        height: 0;
        overflow: hidden;

        .nav-bar-menu-item,
        .nav-bar-menu label {
          opacity: 0;
          height: 0;
          text-transform: inherit;
        }
      }
    }
  }
  .main-page-wrapper .main-page-headline {
    left: 45px;
    font-size: 14px;
  }
}

.nav-bar-menu-item {
  position: relative;

  .p-badge.p-badge-danger {
    position: absolute;
    right: -15px;
    top: 5px;
    min-width: 18px !important;
    min-height: 18px !important;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 7px;
  }
}
