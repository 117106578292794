.common-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -19999999;
  height: 100vh;
  width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: width 0.2s 0.4s, opacity 0.4s, z-index 0s 0.5s;
  flex-direction: column;

  .common-modal-content {
    max-height: 517px;
    display: flex;
    width: 1px;
    transition: width 0.3s linear;
    flex-direction: column;
    overflow: hidden;
    background: #020d1f;
    border-radius: 12px;
    flex: 1 1 auto;

    .common-modal-body {
      opacity: 0;
      transition: opacity 0.2s;
      overflow: auto;
    }
  }

  &.active {
    width: 100%;
    opacity: 1;
    z-index: 3000;
    transition: width 0s, opacity 0.4s;

    .common-modal-content {
      min-width: 320px;
      width: 100%;
      max-width: 540px;
      transition: width 0.3s;
      overflow: inherit;

      .modal-text-content {
        font-family: Open Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.3rem;
        line-height: 2.2rem;
        color: #19191d;
      }

      .common-modal-header,
      .common-modal-body {
        position: relative;
        opacity: 1;
        transition: opacity 0.3s 0.2s;
      }
    }
  }
}

.common-modal-header {
  min-height: 60px;
  padding: 10px 25px 0;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow: inherit;

  align-items: center;

  .p-card-title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    white-space: nowrap;
    width: min-content;
  }

  .button-close-dialog {
    margin-left: auto;
    padding: 0 !important;
    font-size: 22px;
    height: 34px;
    position: absolute;
    right: 1rem;
    top: 16px;

    svg {
      height: 25px;
      width: 25px;
      color: #76859c;
    }

    &:hover {
      svg {
        color: white;
      }
    }
  }
}

.common-modal-body {
  padding: 20px 16px;
}

.modal-body-description {
  padding: 0 24px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

@media (max-width: 586px) {
  .common-modal-wrapper {
    position: fixed;
    top: 0;
    left: 40px;

    &.active {
      .common-modal-content {
        width: 100%;
        left: 0;
        transition: width 0.3s;
      }
    }
  }
}
