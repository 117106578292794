.common-gallery-wrapper {
  flex: 1 1 auto;
  display: flex;
  max-width: 1300px;
  width: 100%;

  .p-galleria-content {
    height: 100%;

    .p-galleria-item-wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .device-detail-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  img {
    max-height: 45vh;
    max-width: 360px;
  }
}

.p-galleria-item {
  padding-bottom: 2rem;
  flex-direction: column;
}

.alt-image-preview {
  font-size: 14px;
  margin-top: 20px;
  font-weight: 400;
  letter-spacing: 2px;
}

.p-galleria-thumbnail-item-content {
  display: flex;
  width: 80px;
  height: 100%;
  margin: 10px 0;
  background: transparent;
  align-items: center;
  justify-content: center;

  img {
    margin: 8px 0;
    max-height: 70%;
    max-width: 100%;
  }
}

.p-galleria-thumbnail-wrapper {
}

.p-galleria .p-galleria-thumbnail-container {
  background: transparent;
  justify-content: center;

  img {
  }
}
