@import '../../assets/styles/lorgar/uiKit/colors';

.desktop-redirect-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: $active-item;
  text-align: center;
}
