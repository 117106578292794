@import '../../assets/styles/lorgar/uiKit/colors';

body .p-dropdown.not-auth-lng {
  position: absolute;
  z-index: 20;
  top: 0;
  right: 0;
  margin: 2rem;
  width: min-content;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  background: transparent;

  .p-inputtext {
    background: transparent;
    color: $text-color;
  }
  .p-dropdown-trigger-icon {
    &:before {
      content: '\e94f';
      color: $text-color;
      transition: 0.3s;
    }
  }
  &:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    border-color: transparent;
  }

  &:hover {
    .p-inputtext {
      background: transparent;
      color: $label-color !important;
    }
    .p-dropdown-trigger-icon {
      &:before {
        color: $label-color;
        transition: 0.3s;
      }
    }
  }
}
