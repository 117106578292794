@import '../../../assets/styles/main-style/modules/colors';

$color: #acb5bd;

.user-profile-card.avatar-card {
  .change-photo-icon {
    position: relative;
    height: 20px;
    top: -2px;
    left: -30px;
    width: 20px;
    bottom: 6px;
    right: 6px;
    cursor: pointer;
    z-index: 100;
    color: $danger-elem;
  }

  .remove-avatar {
    position: relative;
    left: -22px;
    top: 0;
    color: $danger-elem;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .dropzone-message {
    color: $color;
    text-align: center;
    padding: 10px;
  }

  &:focus {
    outline: none;
  }
}
