.promotion-banner-component {
  width: 300px;
  height: 220px;
  border-radius: 6px;
  position: fixed;
  top: 6rem;
  right: -500px;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  transition: 0.4s;
  display: flex;
  flex-direction: column;

  .promotion-banner-wrapper {
  }

  &.active {
    right: 20px;
    opacity: 1;
    transition: 0.4s;
    background: #1d283a;

    .promotion-banner-header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 15px;
      position: relative;
      height: 50px;
      color: white;
      font-size: 17px;
    }

    .promotion-banner-content {
      flex: 1 1 auto;
      height: 100%;
      color: white;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      padding: 15px 20px;

      span {
        z-index: 10;
      }

      img {
        opacity: 0.4;
        position: absolute;
        width: 100%;
      }
    }
  }

  .promotion-banner-footer {
    padding: 5px 15px;
    color: white;
    font-size: 17px;
    height: 50px;
    display: flex;
    justify-content: flex-start;
  }
  .see-more-notification-modal {
    height: 2rem;
  }
  .notification-view-link {
    width: 100%;
    justify-content: center;

    span,
    i {
      font-size: 22px;
      color: #16b89e;
    }

    i {
      font-size: 18px;
      color: #16b89e;
    }
  }

  .pi-times {
    position: absolute;
    left: 10px;
    top: 6px;
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
}

.new-acton-item {
  width: min-content;
  display: flex;
  align-items: center;
  padding: 3px 10px;
  color: white;
  font-size: 13px;
  background: #ea6053;
  position: absolute;
  left: 0;
  top: 0;
}
