.filter-form {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: 100%;
  z-index: -1;

  .filter-wrapper {
    max-height: 0;
    margin-bottom: 1.5rem;
    opacity: 0;
    transition: 0.6s;
  }

  &.active {
    z-index: 1;
    .filter-wrapper {
      max-height: 400px;
      margin-top: 1.5rem;
      opacity: 1;
      transition: 0.6s;
    }
  }
}
