.check-windows-dit-button {
  align-items: center;
  display: flex;
  width: 100%;
  .p-radiobutton {
    margin-right: 10px;
  }
  label {
    margin-right: 30px;
  }
}
