.notification-icon-wrapper {
  display: flex;
  align-items: center;
  padding: 0 1.2rem 0 2rem;
  position: relative;
  svg {
    height: 22px;
    width: 22px;
    cursor: pointer;
  }
  &:hover {
    svg {
      color: white;
    }
  }
}
.p-badge.p-badge-danger {
  position: absolute;
  right: 12px;
  top: 8px;
  background: #d80061 !important;
  width: 10px !important;
  height: 10px !important;
}
