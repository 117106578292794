.filter-nav-bar {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1.5rem;
  width: 100%;
}
.filter-item {
  font-size: 1rem;
  padding: 0 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 3rem;
  background: transparent;
  border-radius: 10px;
  margin-right: 1rem;
  &:hover,
  &.active {
    color: white;
    background: #1d283a;
  }
}
