.access-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -19999999;
  height: 100vh;
  width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: width 0.2s 0.4s, opacity 0.4s, z-index 0s 0.5s;
  flex-direction: column;

  .access-modal-content {
    max-height: 80vh;
    display: flex;
    width: 1px;
    transition: width 0.3s linear;
    flex-direction: column;
    overflow: hidden;
    background: white;
    border-radius: 12px;
    flex: 1 1 auto;

    .access-modal-header {
      min-height: 70px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 31px;
      color: #0a1527;
      width: 100%;
      padding: 0 48px;
      position: relative;

      .download-btn {
        a {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 120%;
          color: #0a1527;
        }
        &:hover {
          background: #18a0fb;
          a {
            color: white;
          }
        }
      }

      .button-close-dialog {
        position: absolute;
        right: -45px;
        top: 4px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 34px;
          height: 34px;
          color: #7a7a7a;
        }
      }
    }

    .access-modal-body {
      padding: 0 48px;
      overflow: auto;

      .modal-body-content {
        padding-bottom: 20px;
      }
    }
  }

  &.active {
    width: 100%;
    opacity: 1;
    z-index: 3000;
    transition: width 0s, opacity 0.4s;

    .access-modal-content {
      min-width: 320px;
      width: 80vw;
      max-width: 1024px;
      transition: width 0.3s;
      overflow: inherit;
    }
  }
}
