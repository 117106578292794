.statistic-daily-modal {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 0;
  height: 100vh;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s, width 0s 0.5s;

  .statistic-daily-modal-wrapper {
    height: 100%;
    width: 0;
    transition: 0.3s;
    max-width: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .statistic-daily-modal-header {
      position: relative;
      display: flex;
      padding: 0.5rem;
      min-height: 5px;

      .pi-times {
        position: absolute;
        right: -21px;
        top: -4px;
        color: white;
        cursor: pointer;
        font-size: 20px;
      }
    }
  }

  &.active {
    width: 100%;
    z-index: 3000;
    transition: opacity 0.3s;
    opacity: 1;

    .statistic-daily-modal-wrapper {
      max-width: 90%;
      width: 500px;
      max-height: 500px;
      height: 90%;
      transition: 0.3s;
      border-radius: 16px;
      background: #0a1527;
      padding: 15px 32px;
    }
  }

  .daily-modal-device-name {
    text-align: center;
    font-size: 1.5rem;
    color: #fff;
  }

  .daily-statistics-full-total {
    padding: 1rem 0;
    display: flex;
    font-size: 1rem;
    flex-direction: column;
    .daily-statistics-full-total-item {
      margin: 0.5rem 0;
    }
  }
}
