.information-document-wrapper {
  margin-top: 15px;
  margin-bottom: 15px;
  overflow: auto;
  height: 100%;
}

.instructions-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
