.community-profile-activity {
  padding: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  $size: 2rem;

  &__col {
    margin-left: 1rem !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .pi {
      font-size: 1.5rem;
      color: #16b89e;
    }

    .count {
      margin-left: 10px;
      font-size: 1.1rem;
      color: white;
    }
  }

  .light-effect-icon {
    border: 1px solid #18a0fb;
    box-sizing: border-box;
    border-radius: 6px;
    width: 35px;
  }
}
