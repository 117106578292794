@import '../../../../../assets/styles/lorgar/uiKit/colors';
.oauth-button-group {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.oauth-btn {
  width: 3rem;
  height: 3rem;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
  border-style: solid;
  border-width: 1px;
  background: $input-bg-color;
  border-color: $base-border-color;
  color: $label-color;
  display: flex !important;
  align-items: center !important;
  margin: 1rem;

  &:hover {
    border-color: $primary-color;
    background: $input-active-bg-color !important;
  }

  &__label {
    white-space: nowrap;
    padding: 0 !important;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    background: transparent;
    margin-left: 5px;
  }

  &:not([disabled]) {
    cursor: pointer;

    &.google:hover {
      background: rgba(52, 168, 83, 0.15);
    }

    &.facebook:hover {
      background: rgba(3, 105, 204, 0.15);
    }
  }
}
