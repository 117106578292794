.device-configuration-block {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  overflow: auto;

  .p-device-card {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }
}

.device-config-item {
  display: none;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  height: 100%;
  position: relative;

  .item-action {
    position: absolute;
    top: 0;
    left: 0;
  }

  &.delete {
    svg {
      color: #ea6053;
      font-size: 14px;
      height: 1.5rem;
      width: 1.5rem;
      cursor: pointer;
    }
  }

  &.select {
    cursor: pointer;
  }

  .device-config-item__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 2rem;
    font-size: 1.1rem;
    position: relative;
  }

  & .device-config-item__body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1 1 400px;
    max-height: 400px;

    img {
      max-height: 80%;
      max-width: 90%;
    }
  }

  & .device-config-item__footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 2rem;
    position: absolute;
    right: 2rem;
    top: 18px;
  }

  &.active {
    display: flex;
  }
}

.configuration-item {
  width: 100%;
}
