.awards-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: #16b89e;
    text-align: center;
  }

  .awards-page-content {
    display: grid;
  }

  .awards-group-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    h3 {
      text-align: center;
      color: #2a80b9;
    }

    .awards-content-group {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: minmax(100px, auto);
      grid-row-gap: 2em;
      margin-bottom: 3rem;

      .awards-content-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        h4 {
          text-align: center;
          margin: 4px 0;
        }

        svg {
          width: 100px;
          height: 70px;
        }

        &.disabled {
          opacity: 0.3;

          h4 {
            text-align: center;
            color: gray;
          }

          svg {
            filter: grayscale(1);
          }
        }
      }
    }
  }
}
