@import '~normalize-scss/sass/normalize';
@import '~primeicons/primeicons.css';
@import '~primeflex/primeflex.css';
@import '~primereact/resources/themes/md-dark-indigo/theme.css';
@import '~primereact/resources/primereact.min.css';
@import '~react-notifications/lib/notifications.css';
@import 'main-style/index';
@import './variables';

body iframe {
  z-index: -1 !important;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;
  width: 320px;
  padding: 0 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
  bottom: 35px;
  top: inherit;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.empty-label {
  font-size: 1.5rem;
  color: gray;
  font-weight: 400;
  font-style: italic;
  text-align: center;
}

body .p-component {
  font-family: 'Open Sans', sans-serif;
}

.p-button-icon-only .p-button-icon-left,
.p-button-text-icon-left .p-button-icon-left,
.p-button-text-icon-right .p-button-icon-right {
  position: static;
  margin-top: 0;
  margin-right: 5px;
}

@import 'main-style/modules/scrollBar';
@import 'fontsProxima';
@import 'breakpoints';
@import 'spacing';
@import 'lorgar/elments/dropdown';
@import 'multiselect';
@import 'button';
@import 'input';
@import 'switch';
@import 'calendar';
@import 'popup';
@import 'tabview';
@import './mobile/common';
@import 'error';
@import 'cropper';
@import 'confirm';
@import 'tooltip';

/* lorgard */

@import 'lorgar/index';
