.free-pagination-item {
  position: absolute;
  top: 5px;
  right: 25px;
}

.promotion-content-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  max-width: 1200px;

  .common-promotion-body {
    padding: 1.2rem;
    flex: 1 1 auto;
    width: 100%;

    .grid-promotion {
      display: grid;
      grid-gap: 0;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      height: 100%;
      width: 100%;

      .promotion-item-wrapper {
        display: flex;
        flex: 1 1 350px;
        max-width: 400px;
        overflow: hidden;
        position: relative;
        max-height: 320px;
        min-height: 300px;
        padding: 16px;

        .promotion-item {
          width: 100%;
          display: flex;
          flex-direction: column;
          border-radius: 16px;
          position: relative;
          overflow: hidden;

          &-media {
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
              height: 100%;
            }

            iframe {
              width: 100%;
              height: 100%;
            }
          }

          &-info {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 60%;
            padding: 32px 24px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            z-index: 10;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.8) -10.96%,
              rgba(0, 0, 0, 0) 100%
            );
            border-radius: 0px 0px 16px 16px;
          }

          .promotion-active-date {
            font-weight: normal;
            font-size: 14px;
            line-height: 13px;
            color: #76859c;
          }

          &-content {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 120%;
            color: #ffffff;
            margin: 12px 0;
            max-width: 260px;
            overflow: hidden;
          }

          &:after {
            content: '';
            width: 100%;
            height: 100%;
            border-radius: 16px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background: rgba(10, 21, 39, 0.4);
          }
        }

        .promotion-item-footer {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 5px 15px;

          svg {
            width: 22px;
            height: 22px;
            color: white;
            cursor: pointer;

            &:hover {
              color: #16b89e;
            }
          }
        }

        .settings-button-block {
          width: 100%;
          display: flex;
          padding: 5px 15px;
          justify-content: flex-start;
        }
      }
    }
  }
}

.archived-item-info {
  width: min-content;
  white-space: nowrap;
  padding: 5px 15px;
  border-radius: 3px;
  color: white;
  background: #16b89e;
}
