@import './mobileDataTable';

@media (max-width: 576px) {
  html {
    -webkit-text-size-adjust: none;

    body {
      -webkit-text-size-adjust: none;
    }
  }

  .auth-page-wrapper {
    padding: 0 10px;
    display: flex;
    align-items: center;

    .sign-card {
      padding: 0 10px 10px;

      .sign-card-form-block {
        padding-top: 5px;
      }

      .sign-card-action-title {
        font-family: Open Sans, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 21px;
        line-height: 26px;
        color: #000000;
      }

      .sign-card .sign-card-form-block {
        padding-top: 2px;
      }
    }
  }

  .page-content-wrapper {
    position: relative;
    padding: 10px 5px;
  }
  .main-page-wrapper {
    padding: 10px;

    .main-page-headline {
      margin: 5px auto;
      z-index: 9999;
      color: white;
      font-size: 13px;
    }

    .p-breadcrumb {
      margin: 12px 0;

      .p-menuitem-link {
        font-size: 14px;
      }
    }

    .user-profile-card {
      padding: 5px 30px;
    }
  }

  .verify-code-input {
    width: 43px !important;
    height: 56px !important;

    .p-inputtext {
      padding: 14px !important;
      width: 43px !important;
      height: 56px !important;
      font-size: 25px !important;
      text-align: center;
    }
  }
  .tertiary-button.p-button {
    height: 30px;
    padding: 0 15px !important;

    span {
      font-style: normal;
      font-weight: bold;
      line-height: 24px;
      letter-spacing: 1px;
      font-size: 12px;
    }
  }
  body .p-accordion .p-accordion-content {
    padding: 10px 0;
  }
  .citizen {
    padding: 5px 5px 20px;
  }
  .p-dialog-mask.p-component-overlay.p-dialog-visible.p-dialog-center {
    .popup-e-doc {
      transform: rotate(270deg) scale(0.75);
      height: 417px;
      min-width: 700px;
    }
  }
  .chart-block {
    padding: 0 5px !important;
  }
  body .p-inputtext,
  body .p-dropdown {
    height: 40px;
  }
  .p-card-title {
    font-size: 17px;
    margin: 0;
  }
  .add-action-button {
    position: absolute !important;
    right: 20px !important;
    top: 29px !important;
  }
}

.common-pages-title-info {
  font-size: 1.2rem;
  font-weight: 600;
  padding-left: 2rem;
  color: white;
}
.common-profile-tab-wrapper {
  max-width: 850px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  background: #354b60;
  border: 3px solid #475b6f;
  border-radius: 6px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}
.common-card-form-block {
  form {
    width: 100%;
  }
}
