.desktop-page-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .desktop-version-block {
    max-width: 280px;

    .p-tabview-nav-container {
      margin-bottom: 20px;
    }

    .check-windows-dit-button {
      margin: 15px 0;
    }

    .download-group-wrapper {
      width: 100%;
      text-align: center;

      a {
        margin: 10px auto;
        display: inline-block;
      }
    }
  }
}
