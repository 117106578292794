.auth-page-wrapper {
  padding: 7vh 0 20px;
  width: 100%;
  overflow: auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}
.auth-page-wrapper.public-page {
  &.contact {
    .sign-card {
      max-width: 400px;
    }

    .common-pages-title-info {
      padding: 0;
    }
  }

  .go-to-main-page {
    > a {
      width: 100%;
    }

    .btn.secondary-btn {
      width: 100% !important;
      white-space: unset;

      span {
        white-space: inherit;
      }
    }
  }

  .sign-card {
    max-width: 400px;
    background: none;
    border: none;
    box-shadow: none;

    .p-tabview.common-desktop-version-block {
      width: 100%;
      .p-tabview-panels {
        height: auto;
      }
      .p-tabview-nav li {
        margin-right: 0;
        flex: 1 1 auto;
        justify-content: center;
        display: flex;

        .p-tabview-nav-link {
          width: 100%;

          span {
            margin: 0 auto;
          }
        }
      }
    }

    .common-profile-tab-wrapper {
      position: relative;

      .link {
        position: absolute;
        right: 10px;
        top: 10px;

        button {
          position: unset;
          right: unset;
          top: unset;
        }
      }
    }
  }
}
