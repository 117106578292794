.daily-statistics-item {
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.04);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  span {
    align-self: flex-start;
  }

  img {
    height: 50px;
  }

  &:hover,
  &.active {
    background: rgba(255, 255, 255, 0.08);
  }

  .total-active-time {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    width: 100%;
    &.green {
      color: #26ff2a;
    }
    &.red {
      color: red;
    }
    &.yellow {
      color: yellow;
    }

    svg {
      margin-right: 0.5rem;
    }
  }
  .daily-statistics-item-total {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    span {
      align-self: center;
    }
  }
}
