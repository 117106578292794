.promotion-view-page {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 0;
  height: 100vh;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s, width 0s 0.5s;

  .promotion-view-wrapper {
    height: 100%;
    width: 0;
    transition: 0.3s;
    max-width: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .promotion-view-header {
      position: relative;
      min-height: 5px;

      .pi-times {
        position: absolute;
        right: -21px;
        top: -23px;
        color: white;
        cursor: pointer;
        font-size: 20px;
      }
    }

    .common-promotion-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      flex: 1 1 auto;
      overflow: auto;

      .promotion-view-media {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          aspect-ratio: 1.7777777777777777;
        }

        iframe {
          width: 100%;
          height: 100%;
        }

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 40%;
          bottom: 0;
          left: 0;
          z-index: 10;
          background: linear-gradient(
            0deg,
            #000000 -8.22%,
            rgba(0, 0, 0, 0) 100%
          );
          transition: 0.6s;
        }

        &:hover {
          &::after {
            opacity: 0;
            bottom: -200px;
            transition: 0.6s;
          }
        }
      }

      .promotion-view-info {
        position: absolute;
        width: min-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        z-index: 15;
        bottom: 47px;
        left: 24px;
        white-space: nowrap;
      }

      .promotion-item-link {
        a {
          font-size: 40px;
          line-height: 38px;
          color: #ffffff;
          margin: 12px 0;
        }
      }

      .promotion-view-content {
        display: flex;
        flex-direction: column;

        .promotion-view-short-text {
          font-size: 32px;
          line-height: 31px;
          color: #ffffff;
          margin: 24px 0;
        }

        .promotion-view-description {
          font-weight: normal;
          font-size: 16px;
          line-height: 120%;
          color: #76859c;
          margin: 24px 0;
        }
      }
    }
  }

  &.active {
    width: 100%;
    z-index: 3000;
    transition: opacity 0.3s;
    opacity: 1;

    .promotion-view-wrapper {
      max-width: 90%;
      width: 800px;
      max-height: 700px;
      height: 90%;
      transition: 0.3s;
      border-radius: 16px;
      background: #0a1527;
      padding: 32px;
    }
  }
}
