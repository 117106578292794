.community-navigation-wrapper {
  display: flex;
  margin-top: 5vh;
  width: 100%;
  align-items: center;

  .filter-button.active {
    background: #1d283a !important;
  }
}
